import { Button, Popconfirm, message } from 'antd';
import classNames from 'classnames';
import { compact, isNil } from 'lodash-es';
import { useNavigate } from 'react-router-dom';
import { TrpcBase } from '../../../utils/trpc';
import {
	constantCheckIn,
	constantLease,
	constantMonthlyStatement,
} from '../edit/categorySelectOption';
import { getOptionValueOrLabel } from '../edit/getOptionValueOrLabel';
import { BlurComponent } from '../myInfo/BlurComponent';
import styles from './overview.module.css';
import type { useApartmentList } from './useApartmentList';

export const CheckDetail = ({
	i,
	pick,
	onDel,
	showDelAction,
	isShowUsed,
	isFirstNode,
}: {
	i: ReturnType<typeof useApartmentList>['list'][number];
	showDelAction: boolean;
	onDel: () => void;
	pick: boolean;
	isShowUsed: boolean;
	isFirstNode: boolean;
}) => {
	const navigate = useNavigate();
	const delAction = TrpcBase.apartment.del.useMutation();

	return (
		<div className={`${pick ? styles.pick : ''}  p-2`}>
			<div
				className={classNames(
					'whitespace-break-spaces break-all',
					styles['open-detail'],
				)}
			>
				{i.category === constantCheckIn && `${i.checkInTimeFormat} 入住`}
				{i.category === constantLease && `${i.checkOutTimeFormat} 退租`}
				{i.category === constantMonthlyStatement &&
					`${i.checkInTimeFormat} 至 ${i.checkOutTimeFormat}`}
			</div>

			{compact([
				{ label: '总计', value: <BlurComponent>{i.amount}</BlurComponent> },
				{
					label: '押金',
					value: <BlurComponent>{i.deposit}</BlurComponent>,
					condition:
						i.category === constantLease || i.category === constantCheckIn,
				},
				{ label: '房租', value: <BlurComponent>{i.rent}</BlurComponent> },

				isShowUsed && {
					label: '本月冷水',
					value: i?.coldWaterMeterReadingLastMonth?.toString(),
					condition: isFirstNode,
				},
				isShowUsed && {
					label: '上月冷水',
					value: i?.coldWaterMeterReadingThisMonth?.toString(),
					condition: !isFirstNode,
				},

				isShowUsed && {
					label: '本月热水',
					value: i?.hotWaterMeterReadingLastMonth?.toString(),
					condition: isFirstNode,
				},
				isShowUsed && {
					label: '上月热水',
					value: i?.hotWaterMeterReadingThisMonth?.toString(),
					condition: !isFirstNode,
				},

				isShowUsed && {
					label: '本月电',
					value: i?.electricMeterReadingLastMonth?.toString(),
					condition: isFirstNode,
				},
				isShowUsed && {
					label: '上月电',
					value: i?.electricMeterReadingThisMonth?.toString(),
					condition: !isFirstNode,
				},

				{ label: '类别', value: getOptionValueOrLabel(i.category) },
				{ label: '备注', value: <BlurComponent>{i.remark}</BlurComponent> },
			])
				.filter((a) => !isNil(a.value))
				.filter((i) => isNil(i?.condition) || i?.condition === true)
				.map((i) => {
					return (
						<div
							key={i.label}
							className="whitespace-break-spaces break-all flex flex-row gap-2"
						>
							<div className="shrink-0">{i.label}</div>
							<div>{i.value}</div>
						</div>
					);
				})}

			<Button
				type="link"
				onClick={() => {
					navigate(`/apartment/print?uuid=${i.uuid}`);
				}}
			>
				打印
			</Button>

			<Button
				type="link"
				onClick={() => {
					navigate(`/apartment/edit?uuid=${i.uuid}`);
				}}
			>
				编辑
			</Button>

			{showDelAction && (
				<Popconfirm
					title="确认进行删除吗?"
					onConfirm={async () => {
						const res = await delAction.mutateAsync({
							uuid: i.uuid,
						});

						message.success('删除成功');

						onDel?.();
					}}
					onCancel={() => {}}
					okText="确定"
					cancelText="取消"
				>
					<Button type="link">删除</Button>
				</Popconfirm>
			)}
		</div>
	);
};
